import React, { useState } from 'react'
import team from '../../assets/team.jpg'
import teamwork from '../../assets/teamwork.jpg'
import growth from '../../assets/growth.jpg'
import mission from '../../assets/mission.jpg'
import techno from '../../assets/tech.jpg'
import vision from '../../assets/vision1.png'
import company from '../../assets/company.jpg'
const AboutToggle = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index)
    }
    return (
        <>
            <div className='p-4  bg-white text-black ' >
                <div className='grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3 text-sm mt-10'>
                    <button
                        className={`${toggleState === 1 ? "active-tabs" : "tabs"} p-2 px-5 border border-purple-600 uppercase rounded hover:scale-105 duration-300 font-semibold`}
                        onClick={() => toggleTab(1)}
                    >The Company</button>
                    <button
                        className={`${toggleState === 2 ? "active-tabs" : "tabs"} p-2 px-5 border border-pink-600 uppercase hover:scale-105 duration-300 font-semibold`}
                        onClick={() => toggleTab(2)}
                    >Our Mission</button>

                    <button
                        className={`${toggleState === 3 ? "active-tabs" : "tabs"} p-2 px-5 border border-red-600 uppercase hover:scale-105 duration-300 font-semibold`}
                        onClick={() => toggleTab(3)}
                    >our Vision</button>

                    <button
                        className={`${toggleState === 4 ? "active-tabs" : "tabs"} p-2 px-5 border border-blue-400 uppercase hover:scale-105 duration-300 font-semibold`}
                        onClick={() => toggleTab(4)}
                    >our team</button>
                </div>


                <div className=' my-20' >
                    <div className=' rounded-2xl h-full' >

                        <div className={`${toggleState === 1 ? "block" : "hidden"} text-black`}
                        >
                            <div className='rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2' >

                                <div>
                                    <img className='max-h-96' src={company} alt="" />
                                </div>
                                <div className='flex flex-col gap-3 w-full md:justify-start md:items-start justify-center items-center text-start' >
                                    <p className=' md:text-xl text-center md:text-start' >
                                    Yashvitech IT Solution is architects of our digital world, responsible for creating the software applications, IT services, and technologies that define modern life. Their functions span diverse industries, their impact reaches every corner of society, and their innovations continually shape the future. As they navigate the ever-evolving technological landscape, their commitment to innovation, excellence, and ethical practices ensures that they remain at the forefront of driving progress and transforming possibilities into realities.
                                    </p>

                                    <ul className='flex flex-col gap-3 list-disc md:text-xl' >
                                        <li>Quality, innovation, results. </li>
                                        <li>  Passionate collaboration.</li>
                                        <li> Social responsibility.</li>
                                        <li>Ongoing learning and development.</li>
                                    </ul>

                                </div>

                            </div>
                        </div>

                        <div className={`${toggleState === 2 ? "block" : "hidden"}`}
                        >
                            <div className='rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2' >

                                <div className='flex flex-col gap-3' >
                                    <p className='md:text-right text-center' >
                                    At Yashvitech IT Solution, our mission is to harness the power of technology to drive innovation, empower businesses, and enrich lives. We are dedicated to delivering exceptional software solutions and IT services that transform challenges into opportunities, inspire growth, and positively impact our clients, employees, and the communities we serve.
                                        </p>
                                    <ul className='flex flex-col gap-3  md:text-xl md:text-right text-center ' >
                                        <li>  Empowering success through exceptional services. </li>
                                        <li>  Collaborative approach for customized solutions.</li>
                                        <li>  Focus on quality, results, and customer satisfaction.</li>
                                        <li> Operating with integrity and social responsibility.</li>
                                    </ul>
                                </div>
                                <div className='order-first md:order-none grid' >
                                    <img className='max-h-96  ' src={mission} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={`${toggleState === 3 ? "block" : "hidden"}`}
                        >
                            <div className='rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2' >

                                <div>
                                    <img className='max-h-96' src={vision} alt="" />
                                </div>
                                <div className='flex flex-col gap-3 w-full md:justify-start md:items-start justify-center items-center text-start' >
                                    <p className=' md:text-xl text-center md:text-start' >
                                    At Yashvitech IT Solution, our vision is to be at the forefront of shaping a digital future where technology transforms lives, businesses, and societies. We are driven by the belief that innovation has the power to create positive change and drive progress in a rapidly evolving world.
                                    </p>

                                    <ul className='flex flex-col gap-3 list-disc md:text-xl' >
                                        <li>Quality, innovation, results. </li>
                                        <li>  Passionate collaboration.</li>
                                        <li> Social responsibility.</li>
                                        <li>Ongoing learning and development.</li>
                                    </ul>

                                </div>

                            </div>
                        </div>

                        <div className={`${toggleState === 4 ? "block" : "hidden"}`}
                        >
                            <div className='rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2' >


                                <div>
                                    <p className='text-left' >
                                    At Yashvitech IT Solution, our team is not just a group of individuals; we are a family united by our shared commitment to innovation, excellence, and client success. Together, we are dedicated to helping our clients navigate the ever-evolving digital landscape with state-of-the-art solutions tailored to their unique needs.
                                    <br /><br />
                                    Our success is not solely measured by our achievements but by the collective efforts of each team member, working tirelessly to turn challenges into opportunities and ideas into reality. We're not just in the business of technology; we're in the business of transforming possibilities into realities.
                                    <br /><br />
                                    We invite you to get to know our team personally, as their passion and expertise are the driving forces that make Yashvitech IT Solution a leader in the industry. Stay connected with us as we continue to innovate, pushing the boundaries of technology to bring you tomorrow's solutions today.
                                    </p>
                                </div>
                                <div>
                                    <img className='max-h-96' src={teamwork} alt="" />

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default AboutToggle
