import React from 'react'

const Loader = () => {
    return (
        <>
            <div class="flex justify-center bg-black items-center min-h-screen">
                <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                <div className='absolute animate-wiggle' >
                    <img className='w-24 h-24' src="LOGO.png" alt="" />
                </div>
            </div>
        </>
    )
}

export default Loader
