import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import SwiperNavButton from './SwiperNavButton';
import Images from './AboutImages'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const MainSwiperAbout = () => {
    return (
        <>
            <div>
                <Swiper
                  
                    speed="800"
                    autoplayDisableOnInteraction={false}
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={20}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    parallax={true}
                    slidesPerView={1}
                    // navigation
                    // pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    onSlideChange={() => console.log('slide change')}
                   
                    onSwiper={(swiper) => console.log(swiper)}
                    className="bg-white w-full flex "
                >
                    {Images.map((img, index) => (

                        <SwiperSlide key={index} className="   relative   group overflow-hidden "  >
                         

                           <div className='  object-cover bg-no-repeat bg-cover hover:scale-105 duration-1000'  style={{backgroundImage:`url(${img.img})` }} > 
                            <div className=' text-base sm:text-6xl py-20 sm:pt-40 text-center h:40  sm:h-96 animate-flash text-[#ff6600] font-bold  '  > {img.designation} </div>   </div>
                        </SwiperSlide>
                    ))}
                    <div className='  py-5 pt-2' >
                        <SwiperNavButton />
                    </div>
                </Swiper>
            </div>
        </>
    )
}

export default MainSwiperAbout
