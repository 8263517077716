import one from '../assets/1.png'
// import two from '../assets/2.png'
// import three from '../assets/3.png'
// import four from '../assets/4.png'

export default [
    {
        // img: one,
        img: "wev_dev1.jpg",
        // name: "Priytam Patel",
        designation: "Web Service",
        // description: "Co-founder of onlinehoja and work as an Mern Developer"
    },
    {
        // img: one,
        img: "mobile_app.jpg",
        // name: "roshan rana",
        designation: "Mobile Apps",
        // description: "Co-founder of onlinehoja and work as an Mern Developer"
    },
    {
        // img: one,
        img: "software_dev.jpg",
        // name: "roshan",
        designation: "Software",
        // description: "Co-founder of onlinehoja and work as an Mern Developer"
    },
    {
        // img: one,
        img: "home_marketing.jpg",
        // name: "roshan",
        designation: "Digital Marketing",
        // description: "Co-founder of onlinehoja and work as an Mern Developer"
    },

]