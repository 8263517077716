import React from "react";
import { BsArrowDownShort } from "react-icons/bs";
import education from "../assets/education1.jpg";
import {
    BsCart4,
    BsHandbagFill,
    BsFillChatRightTextFill,
    BsCalculator,
    BsFillBuildingsFill,
    BsFillCalendarCheckFill,
    BsFillBuildingFill,
    BsFillCarFrontFill,
    BsFileEarmarkMusic,
} from "react-icons/bs";
import { RiComputerFill } from "react-icons/ri";
import { GiAmericanFootballHelmet } from "react-icons/gi";
import { FcAndroidOs } from "react-icons/fc";
import { FaHeartbeat, FaBed } from "react-icons/fa";
import { MdCastForEducation } from "react-icons/md";
import { SiInteractiondesignfoundation } from "react-icons/si";
// import web from "../assets/website1.jpg";
import android from "../assets/android2.jpg";
import ecom from "../assets/ecomerce1.jpg";
import ui from "../assets/ui.jpg";
import health from "../assets/health.jpg";
import cart from "../assets/cart.jpg";
import hospitality from "../assets/hospitality.jpg";
import social from "../assets/social.jpg";
import accounting from "../assets/accounting.jpg";
// import realestate from "../assets/realestate.jpg";
import event from "../assets/event.jpg";
import spots from "../assets/sports1.jpg";
import car from "../assets/mustang.jpg";
import music from "../assets/music.jpg";
import { Link } from "react-router-dom";
// import { useGetServiceQuery } from "../services/profile";
import Loader from "./Loader";
import { Image } from "antd";

const ServiceHome = () => {
    //   const { data, isLoading } = useGetServiceQuery();
    //   console.log(data, "30");
    return (
        <>
            {false ? (
                <Loader />
            ) : (
                <>
                <div class="flex justify-center bg-[#6A5ACD] p-4">
                    <div class="flex flex-col justify-center items-center ">
                        <div class="text-white text-3xl font-medium">Our Services</div>
                        <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">

                            <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-0">

                                <div class="flex flex-col md:flex-row items-center justify-center  ">
                                    <div class="  items-center justify-center flex py-2">
                                        <div class="flex flex-col  items-center justify-center text-center">
                                            <Image src="web.jpg" alt="" class="rounded-full"  width={150}
                  height={150}
                  style={{borderRadius:"50%"}} />
                                            <div class="font-bold">Website Development</div>
                                            <div class="text-stone-600 font-small m-2"> 
                                            We specialize in developing custom software solutions tailored to meet the unique needs of businesses. Our team of skilled developers uses Python, React, and PHP to create high-quality, scalable, and user-friendly applications that enhance efficiency and productivity.
                                                </div>
                                            
                                               </div>
                                    </div>

                                </div>
                            </div>
                            <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-0">

                                <div class="flex flex-col md:flex-row items-center justify-center  ">
                                    <div class="  items-center justify-center flex py-2">
                                    
                                        <div class="flex flex-col  items-center justify-center text-center">
                                     
                                            {/* <img src=".jpg" alt="" class="rounded-full" /> */}
                                            <Image src="mobile.jpg" alt="" class="rounded-full"  width={150} height={150} style={{borderRadius:"50%"}} />
                                            <div class="font-bold">Mobile App Development</div>
                                            <div class="text-stone-600 font-small m-2"> 
                                            YASHVITECH specializes in developing tailor-made mobile applications for diverse platforms including iOS and Android. Our experienced team of developers, proficient in languages like Java, Kotlin, Swift, and React Native, create customized apps that align perfectly with your unique requirements.
                                                </div>
                                      
                                          
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-0">

                                <div class="flex flex-col md:flex-row items-center justify-center  ">
                                    <div class="  items-center justify-center flex py-2">
                                        <div class="flex flex-col  items-center justify-center text-center">
                                            {/* <img src="https://source.unsplash.com/150x150/?girl,woman" alt=""
                                                class="rounded-full" /> */}
                                                <Image src="training.png" alt="" class="rounded-full"  width={150} height={150} style={{borderRadius:"50%"}}/>
                                            <div class="font-bold">Software Training</div>
                                            <div class="text-stone-600 font-small m-2">
                                            We offer training programs designed to nurture talent and enhance skills in the ever-evolving IT landscape. Our experienced trainers provide hands-on training in Python, React, PHP, and other relevant technologies, equipping individuals with the expertise they need to excel in the industry.</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center bg-[#6A5ACD] p-4">
                    <div class="flex flex-col justify-center items-center ">
                    
                        <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">

                            <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-0">

                                <div class="flex flex-col md:flex-row items-center justify-center  ">
                                    <div class="  items-center justify-center flex py-2">
                                        <div class="flex flex-col  items-center justify-center text-center">
                                            {/* <img src="https://source.unsplash.com/150x150/?man,boy" alt="" class="rounded-full" /> */}
                                            <Image src="api.jpeg" alt="" class="rounded-full"  width={150} height={150} style={{borderRadius:"50%"}}/>
                                            <div class="font-bold">API Development</div>
                                            <div class="text-stone-600 font-small m-2">
                                            At YASHVITECH, we offer top-notch API (Application Programming Interface) building services to help businesses leverage the power of seamless data communication and integration. Our experienced team of developers specializes in designing, developing, and deploying APIs tailored to your specific needs.
                                                </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-0">

                                <div class="flex flex-col md:flex-row items-center justify-center  ">
                                    <div class="  items-center justify-center flex py-2">
                                        <div class="flex flex-col  items-center justify-center text-center">
                                   
                                            {/* <img src="https://source.unsplash.com/150x150/?girl" alt="" class="rounded-full" /> */}
                                            <Image src="maintenance.jpeg" alt="" class="rounded-full"  width={150} height={150} style={{borderRadius:"50%"}}/>
                                            <div class="font-bold">Software Maintenance and Support</div>
                                            <div class="text-stone-600 font-small m-2">
                                            By choosing YASHVITECH for software maintenance and support, you're partnering with a team dedicated to the ongoing success of your software applications. We ensure that your software remains secure & user-friendly, allowing you to focus on your core business activities. Let us handle the technical aspects while you concentrate on growing your business.
                                                </div>
                                         
                                          
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[33%] px-0">

                                <div class="flex flex-col md:flex-row items-center justify-center  ">
                                    <div class="  items-center justify-center flex py-2">
                                        <div class="flex flex-col  items-center justify-center text-center">
                                            {/* <img src="https://source.unsplash.com/150x150/?girl,woman" alt=""
                                                class="rounded-full" /> */}
                                            <Image src="marketing.jpeg" alt="" class="rounded-full"  width={150} height={150} style={{borderRadius:"50%"}}/>
                                                <div class="font-bold">Digital Marketing and Services</div>
                                            <div class="text-stone-600 font-small m-2"> 
                                            At YASHVITECH, we understand the nuances of the digital realm and leverage our expertise to boost your brand visibility, engage your audience, and drive conversions. Our digital marketing strategies are not just campaigns; they are tailored experiences crafted to enhance your online footprint, generate leads, and foster customer loyalty.
                                                </div>
                                            
                                         
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </>
    );
};

export default ServiceHome;
