import { Modal, Form, Input, Button } from "antd";
import React, { useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Image } from "antd";
import { Link } from "react-router-dom";

const Solution = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitile] = useState('');
  const showModal = async (type) => {
    setTitile(type)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        open={isModalOpen} onOk={handleOk} footer={null} onCancel={handleCancel} width={400}
      >
        <h1 style={{ textAlign: 'center', fontSize: "20px" }}>{title}</h1>
        <Form
          className=" m-auto sm:pr-0 px-4 w-[99%]"
          layout="vertical"
          name="basic"
          form={form}
          // onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            Width: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <Form.Item
            className="m-0 p-0 sm:w-full "
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input className="w-full" placeholder="Name" />
          </Form.Item>

          <Form.Item
            className="m-0 p-0 w-full "
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            className="m-0 p-0 w-full "
            label="Mobile No"
            name="contact_number"
            style={{ Width: 600 }}
            rules={[
              {
                required: true,
                message: "Please input your mobile no!",
              },
            ]}
          >
            <Input placeholder="Mobile Number" className=" w-[100%]" />
          </Form.Item>

          <div style={{ textAlign: "center", marginTop: "5px" }}>
            <Form.Item className="m-0 p-0">
              <Button

                htmlType="submit"
                style={{ backgroundColor: "blue", color: "white" }}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div
        className="bg-[url(inner_page_banner.jpg)  ] font-[Arial] py-20 bg-center text-white "
        style={{ backgroundImage: "url(inner_page_banner.jpg)" }}
      >
        <div className=" pl-10 sm:pl-44 sm:py-5">
          <button className="font-[550] text-[40px] ">Courses</button>
          <div className="flex text-base gap-2 ">
            <button className="text-sky-400 "><Link to="/">Home</Link></button>{" "}
            <FaGreaterThan style={{ marginTop: "6px" }} />{" "}
            <button> Courses </button>
          </div>
        </div>
      </div>




























      <div className="flex justify-center ">
        <div className="flex flex-col justify-center items-center bg-slate-100 max-w-full">
          <div className="flex flex-col items-center justify-evenly gap-2 my-12 lg:px-10 lg:flex-row space-y-1 lg:space-y-0 ">
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[320px] md:w-[70%] lg:w-[30%] overflow-hidden">
              <Image src="Full-Stack.jpg" alt="" class="rounded-full" width={250} />

              {/* <h1 className="text-sm tracking-tight font-semibold  ">
                Full Stack Development
              </h1> */}
              <div className="flex justify-center gap-2 mt-2">


                <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  " onClick={() => showModal('Full Stack Development')}>
                  Click Here For Demo
                </button>

              </div>
            </div>
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[320px] md:w-[70%] lg:w-[30%] overflow-hidden">
              <Image src="frontend.png" alt="" class="rounded-full" width={250} />

              {/* <h1 className="text-sm tracking-tight font-semibold  ">
                Full Stack Development
              </h1> */}
              <div className="flex justify-center gap-2 mt-2">


                <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  " onClick={() => showModal('Frontend Development')}>
                  Click Here For Demo
                </button>

              </div>
              

            </div>
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[320px] md:w-[70%] lg:w-[30%] overflow-hidden">
              <Image src="backend1.png" alt="" class="rounded-full" width={250} />

              {/* <h1 className="text-sm tracking-tight font-semibold  ">
                Full Stack Development
              </h1> */}
              <div className="flex justify-center gap-2 mt-2">


                <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  " onClick={() => showModal('Backend Development')}>
                  Click Here For Demo
                </button>

              </div>
              

            </div>
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[320px] md:w-[70%] lg:w-[30%] overflow-hidden">
              {/* <img
                // src="team-member-1.jpg"
                src="marketing.png"
                className="rounded-full mt-1 "
                alt=""
                width={200}
              /> */}
              <Image src="marketing.png" alt="" class="rounded-full" width={250} />
              {/* <h1 className="text-sm tracking-tight font-semibold     ">
                Backend Development
              </h1> */}
              <div className="flex justify-center gap-2 mt-2">
                <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  " onClick={() => showModal('Digital Marketing')}>
                  Click Here For Demo
                </button>
              </div>
            </div>
            {/* <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[320px] md:w-[70%] lg:w-[30%] overflow-hidden">
              <img
                src="team-member-1.jpg"
                className="rounded-full mt-1 "
                alt=""
                width={200}
              />
              <h1 className="text-sm tracking-tight font-semibold     ">
                Digital Marketing
              </h1>
              <div className="flex justify-center gap-2 mt-2">
                <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  " onClick={() => showModal('Digital Marketing')}>
                  Click Here For Demo
                </button>
              </div>
            </div> */}

            
          </div>
        </div>
      </div>

















































      <div className="flex justify-center ">
        <div className="flex flex-col justify-center items-center bg-slate-100 max-w-full">
          <div className="flex flex-col items-center justify-evenly gap-2 my-12 lg:px-10 lg:flex-row space-y-8 lg:space-y-0 ">
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <h1 className="text-lg tracking-tight font-semibold text-green-600     ">
                {/* Consignment Booking (Percel parchune) */}
                HTML
              </h1>
              <p className="text-black mt-3 text-letf w-[90%] lg:w-[100%]">
                {/* Multi-Company / Branch / User <br /> Booking / GR / LR <br />{" "}
                Challan / GDM / Dispatch <br /> Crossing Entry / Challan <br />
                Party Bill / Bill Settlement
                <br /> Delivered / Undelivered Godown Status <br /> Delivery
                Money Reciept
                <br /> Service Tax Management <br /> Financial Accounting <br />{" "}
                Trip Statement <br /> Daily and Monthly Delivery Register <br />{" "}
                Challan / Booking / Crossing Register{" "} */}
                HTML is the backbone of the World Wide Web, providing the structure and semantics necessary to create web pages. HTML elements are delineated by tags, written using angle brackets. It has evolved over the years to support multimedia, interactivity, and improved accessibility. As web technologies continue to advance, HTML will likely remain a fundamental tool for web developers, shaping the digital landscape for years to come.
              </p>
              <img
                src="https://aanshisolutions.com/Image/Software-management.png"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://en.wikipedia.org/wiki/HTML' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <h1 className="text-lg tracking-tight font-semibold text-green-600    ">
                {/* Commission Agent Thrid Party */}
                CSS
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {/* Multi-Company / Branch / User <br />
                Booking / GR /
                <br />
                Challan / GDM / Dispatch Crossing Entry / Challan
                <br />
                Party Bill / Bill Settlement
                <br /> Delivered / Undelivered Godown Status
                <br />
                Self / Hited / Attached Vehicle Management
                <br /> Freight Payment
                <br /> Commission / Vasuli Register
                <br />
                Financial Acconting
                <br />
                Daily and Monthly Delivery Register
                <br />
                Challan / Booking / Crossing Register{" "} */}
                CSS is an essential technology for web development, allowing developers to control the visual presentation of HTML documents. With a good understanding of CSS selectors, properties, and layout techniques, web designers can create visually appealing and responsive websites that cater to a wide range of devices and screen sizes. As web development continues to evolve, CSS will remain a vital skill for creating engaging and user-friendly web experiences.
              </p>
              <img
                src="https://aanshisolutions.com/Image/icon_partners-325.jpg"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://en.wikipedia.org/wiki/CSS' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-left items-center p-7 bg-white shadow-md rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-lg font-[Arial] tracking-tight font-semibold text-green-600    ">
                Javascript
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {/* {" "}
                Vehicle Document Management
                <br /> Fuel Management
                <br /> Tyre Management
                <br /> Spare and Tyre Inventory
                <br /> Inventory Management
                <br /> Financial Accounting
                <br /> Daily and Routine Expenses
                <br /> Scheduled and Routine Service Setup
                <br /> GPS Vehicle Tracking
                <br /> Document/Service Other Reminders
                <br /> Driver Management
                <br /> Driver Licence Reminders */}
                JavaScript is a foundational technology in web development, empowering developers to build interactive and responsive web applications. Its continued evolution through standards like ECMAScript ensures its relevance and adaptability in the ever-changing landscape of web development. Whether you're a front-end developer, a full-stack engineer, or working on other web-related tasks, JavaScript is a valuable tool in your toolkit.
              </p>
              <img
                src="https://aanshisolutions.com/Image/trucks.png"
                className="rounded-full mt-10"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://en.wikipedia.org/wiki/JavaScript' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-lg text-left tracking-tight font-semibold text-green-600    ">
                React Js
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {/* {" "}
                Multi-Company / Branch / User
                <br />
                Booking / GR / LR
                <br />
                Challan / GDM / Despatch
                <br />
                Crossing Entry / Challan
                <br />
                Party Bill / Bill Settlement
                <br />
                Delivered / Undelivered Godown Status
                <br />
                Self / Hited / Attached Vehicle Management
                <br />
                Freight Payment
                <br />
                Commission / Vasuli Register
                <br />
                Financial Acconting
                <br />
                Daily and Monthly Delivery Register
                <br />
                Challan/Booking / Crossing Register */}
                React.js has become a cornerstone of modern web development due to its component-based architecture, efficient rendering with the Virtual DOM, and strong developer ecosystem. As the web development landscape continues to evolve, React remains a popular and versatile choice for building dynamic and responsive user interfaces for web and mobile applications. You don’t have to build your whole page in React. Add React to your existing HTML page, and render interactive React components anywhere on it.
              </p>
              <img
                src="https://aanshisolutions.com/Image/images.png"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://react.dev/' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center ">
        <div className="flex flex-col justify-center items-center bg-slate-100 max-w-full">
          {/* <div className="text-3xl text-green-600 font-bold mt-4 ">Testimonial</div> */}
          {/* <div className="text-2xl text-black font-bold mt-2 ">What our Customers Say</div> */}
          <div className="flex flex-col items-center justify-evenly gap-2 my-12 lg:px-10 lg:flex-row space-y-8 lg:space-y-0 ">
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-lg tracking-tight text-center font-semibold text-green-600    ">
                {/* Consignment Booking (Parcel perchune) */}
                Python
              </h1>
              <p className="text-black mt-3 text-letf w-[90%] lg:w-[100%]">
              Python is a versatile and powerful programming language that has made a significant impact across various domains, from web development and data science to artificial intelligence and scientific research. Its simplicity, readability, and extensive library support continue to attract both beginners and experienced programmers, making it a vital tool in the world of technology and software development. Python consistently ranks as one of the most popular programming languages.
                {/* Multi-Company / Branch / User <br /> Booking / GR / LR <br />{" "}
                Challan / GDM / Dispatch <br /> Crossing Entry / Challan <br />
                Party Bill / Bill Settlement
                <br /> Delivered / Undelivered Godown Status <br /> Delivery
                Money Reciept
                <br /> Service Tax Management <br /> Financial Accounting <br />{" "}
                Trip Statement <br /> Daily and Monthly Delivery Register <br />{" "}
                Challan / Booking / Crossing Register{" "} */}
              </p>
              <img
                src="https://aanshisolutions.com/Image/Software-management.png"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://www.python.org/' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-lg tracking-tight text-center font-semibold text-green-600    ">
                Django
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {/* Multi-Company / Branch / User <br />
                Booking / GR /
                <br />
                Challan / GDM / Dispatch Crossing Entry / Challan
                <br />
                Party Bill / Bill Settlement
                <br /> Delivered / Undelivered Godown Status
                <br />
                Self / Hited / Attached Vehicle Management
                <br /> Freight Payment
                <br /> Commission / Vasuli Register
                <br />
                Financial Acconting
                <br />
                Daily and Monthly Delivery Register
                <br />
                Challan / Booking / Crossing Register{" "} */}
                Django is a powerful and versatile web framework that simplifies web application development while promoting best practices in security, scalability, and maintainability. Its comprehensive feature set, active community, and commitment to developer productivity have made it a go-to choice for building a wide range of web applications across various industries. Django takes security seriously and helps developers avoid many common security mistakes.
              </p>
              <img
                src="https://aanshisolutions.com/Image/icon_partners-325.jpg"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://www.djangoproject.com/' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-left items-center p-7 bg-white shadow-md rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-lg font-[Arial] tracking-tight font-semibold text-green-600    ">
                PHP
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {/* {" "}
                Vehicle Document Management
                <br /> Fuel Management
                <br /> Tyre Management
                <br /> Spare and Tyre Inventory
                <br /> Inventory Management
                <br /> Financial Accounting
                <br /> Daily and Routine Expenses
                <br /> Scheduled and Routine Service Setup
                <br /> GPS Vehicle Tracking
                <br /> Document/Service Other Reminders
                <br /> Driver Management
                <br /> Driver Licence Reminders */}
                PHP, which stands for "Hypertext Preprocessor," is a widely used server-side scripting language for web development. It is known for its versatility, ease of use, and integration capabilities, making it one of the most popular choices for building dynamic web applications. In this article, we will explore PHP in more detail, covering its history, key features, common use cases, and its role in modern web development. PHP/FI was the first version to be publicly released in 1995.
              </p>
              <img
                src="https://aanshisolutions.com/Image/trucks.png"
                className="rounded-full mt-10"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://www.php.net/' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">

              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-lg text-left tracking-tight font-semibold text-green-600    ">
                Database
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {/* {" "}
                Multi-Company / Branch / User
                <br />
                Booking / GR / LR
                <br />
                Challan / GDM / Despatch
                <br />
                Crossing Entry / Challan
                <br />
                Party Bill / Bill Settlement
                <br />
                Delivered / Undelivered Godown Status
                <br />
                Self / Hited / Attached Vehicle Management
                <br />
                Freight Payment
                <br />
                Commission / Vasuli Register
                <br />
                Financial Acconting
                <br />
                Daily and Monthly Delivery Register
                <br />
                Challan/Booking / Crossing Register */}
                {/* databases are the backbone of modern information systems, facilitating the efficient storage, retrieval, and manipulation of data across various industries and applications. Their importance in the digital age cannot be overstated, as they enable businesses and institutions to harness the power of data for informed decision-making and improved quality of life. As technology continues to advance, databases will continue to evolve and adapt to meet the growing demands of the data-driven world. */}
                A database is a structured collection of data that is organized and stored for easy retrieval, management, and manipulation. Databases are fundamental to the field of information technology and play a critical role in various aspects of our daily lives, from managing business operations to powering social media platforms and online shopping websites. The database system or an application associated with the database.
              </p>
              <img
                src="https://aanshisolutions.com/Image/images.png"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='https://en.wikipedia.org/wiki/Database' target="_blank">

                  <button className="text-sm px-2 py-1 font-semibold bg-[#6A5ACD] text-white rounded  ">
                    Request A Demo
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SwiperAbout /> */}
      {/* <Testimonial /> */}
    </div >
  );
};

export default Solution;