import React from "react";
import { Route, Routes } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About";
import Career from "./pages/Career";
import HomePage from "./pages/HomePage";
import MainPage from "./pages/MainPage";
import ContactUs from "./components/ContactUs";
import Service from "./pages/Service";
import Solution from "./pages/Solution";
import Blog from "./pages/Blog";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ServicesDetail from "./pages/ServicesDetail";
import { Email } from "./pages/Email";

const App = () => {
  return (
    <>
      <ScrollTop />
      <Routes>
        {/* Home Users */}
        <Route path="/" element={<MainPage />}>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="service" element={<Service />} />
          <Route path="course" element={<Solution />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="servicesdetail" element={<ServicesDetail />} />
          <Route path="blog" element={<Blog />} />
          <Route path="career" element={<Career />}></Route>
          <Route path="contact" element={<ContactUs />} />
          <Route path="email" element={<Email />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
