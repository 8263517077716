import React from 'react'
import { useSwiper } from 'swiper/react'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';

const SwiperNavButton = () => {
    const swiper = useSwiper()
    return (
        <>
            <div className='flex gap-5 items-center w-full justify-center pb-4' >
                <button
                    className='h-12 w-12 bg-gradient-to-r from-pink-first flex text-white hover:text-white justify-center items-center text-2xl hover:scale-105 duration-300 to-pink-second rounded-full'
                    onClick={() => swiper.slidePrev()}
                ><BsFillArrowLeftCircleFill /></button>
                <button
                    className='h-12 w-12 bg-gradient-to-r from-pink-first flex text-white hover:text-white justify-center items-center text-2xl hover:scale-105 duration-300 to-pink-second rounded-full'
                    onClick={() => swiper.slideNext()}
                ><BsFillArrowRightCircleFill /></button>
            </div>
        </>
    )
}

export default SwiperNavButton
