
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../components/ReusableFormField/FormikControl";
import TextError from "../components/TextError";
// import { usePostContactMutation } from "../services/profile";
import { toast, ToastContainer } from "react-toastify";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
const MainContactForm = () => {
  // const [contactUs] = usePostContactMutation();

  // Initail State

  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    subject: "",
    message: "",

  };

  const onSubmit = async (values, onSubmitProps) => {
    console.log("Form Data", values);
    emailjs.sendForm('service_h0q8wcj', 'template_9bpvet6', values, 'jOfpEsyxCNPQkkwuz')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    }); // Main Form Data
    onSubmitProps.setSubmitting(false); // This set isSubmittion false after save
   
    onSubmitProps.resetForm(); // Reset Form on submit
    // mutate(values)
  };

  const validationSchema = Yup.object({
    name: Yup.string().min(3, 'Name must be at least 3 letters').max(25, 'Text must be at most 25 characters').required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    subject: Yup.string().min(5, 'Subject must be at least 5 letters').max(100, 'Subject must be at most 100 characters').required("Required"),
    mobileNumber: Yup.string().matches(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/, 'Invalid mobile number').required("Required"),
    message: Yup.string().min(10, 'Message must be at least 10 letters').max(200, 'Message must be at most 200 characters').required("Required"),
  });
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_h0q8wcj', 'template_9bpvet6', form.current, 'jOfpEsyxCNPQkkwuz')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <>
      <ToastContainer />
      <div className=" bg-white">
        

        <div className="md:w-11/12 w-full m-auto    m border-t-0">
          <div className="p-4">
            <div className=" text-center ">
              <h1 className="text-3xl " >Contact Us</h1>
             
             
            </div>

            {/* Form */}
            <div className=" w-full h-full mt-10">
              <div className="flex flex-col gap-4 md:p-5">
                
                
                      <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-6   justify-center items-center  ">
                        <div className="grid md:grid-cols-2 grid-cols-1 w-full gap-3 ">
                          {/* User Name */}
                          <input
                className=" bg-[#f8f8f8] text-black border-collapse border-1 font-thin border-gray-400  placeholder:italic placeholder:text-sm placeholder:text-slate-400   focus:outline-none focus:border-black   focus:border-transparent focus:ring-1"
                            label="Full Name"
                            type="text"
                            name="user_name"
                            placeholder="Enter Full Name"
                          />

                          {/* Email */}
                          <input

className=" bg-[#f8f8f8] text-black border-collapse border-1 font-thin border-gray-400  placeholder:italic placeholder:text-sm placeholder:text-slate-400   focus:outline-none focus:border-black   focus:border-transparent focus:ring-1"
                            type="email"
                            name="user_email"
                            placeholder="Email address"
                          />
                        </div>

                        <div className="grid md:grid-cols-2 grid-cols-1 w-full gap-3 ">
                          {/* Phone Number */}
                          <input

className=" bg-[#f8f8f8] text-black border-collapse border-1 font-thin border-gray-400  placeholder:italic placeholder:text-sm placeholder:text-slate-400   focus:outline-none focus:border-black   focus:border-transparent focus:ring-1"
                            type="number"
                            name="mobileNumber"
                            placeholder="mobile Number"
                          />

                          {/* Phone Number */}
                          <input

className=" bg-[#f8f8f8] text-black border-collapse border-1 font-thin border-gray-400  placeholder:italic placeholder:text-sm placeholder:text-slate-400   focus:outline-none focus:border-black   focus:border-transparent focus:ring-1"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                          />
                        </div>

                        {/* Email */}

                        <div className="flex flex-col gap-1 w-full ">

                        
                        <textarea
                         
                         className=" bg-[#f8f8f8] text-black border-collapse border-1 font-thin border-gray-400  placeholder:italic placeholder:text-sm placeholder:text-slate-400   focus:outline-none focus:border-black   focus:border-transparent focus:ring-1"
                          name="message"
                          placeholder="message"
                        />
</div>
                        {/* Submit Button */}
                        <input
                          type="submit"
                          value="Send"
                          className=" mt-4 disabled:bg-gray-400  bg-sky-300   rounded-md  p-2 text-white active:bg-green-800  disabled:cursor-not-allowed hover:text-xl duration-300"
                          // disabled={!formik.isValid || formik.isSubmitting}
                        />
                          {/* Submit
                        </button> */}

                      </form>
                    
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContactForm;
