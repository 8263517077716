import React, { useEffect } from "react";
import {
  BsFillBuildingFill,
  BsGraphUpArrow,
  BsNewspaper,
} from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import {
  BsApple,
  BsFacebook,
  BsInstagram,
  BsYoutube,
  BsTelegram,
  BsLinkedin,
} from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import MainContactForm from "./MainContactForm";
import { Link } from "react-router-dom";
import logo from '../assets/MainLogo.png'
import { FaGreaterThan } from "react-icons/fa";

const ContactUs = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.6,
        },
      });
    }
    if (!inView) {
      animation.start({ x: "-100vh" });
    }
  }, [inView]);
  return (
    <>
      <div className="bg-white w-full text-black">
      <div className="bg-[url(inner_page_banner.jpg)  ] py-20 bg-center text-white " style={{backgroundImage:"url(inner_page_banner.jpg)" }} >

<div className=' pl-10 sm:pl-44 sm:py-5' >

<button className='font-[550] text-[40px] ' >Contact Us</button>
<div className="flex text-base gap-2 ">

<button className='text-sky-400 '> Home</button> <FaGreaterThan style={{marginTop:"6px" }}  /> <button> Contact Us </button>
</div>

</div>
</div>
        <div className="w-10/12 m-auto">

          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 py-10">
            <h2 className=" text-5xl text-center md:col-span-3 md:justify-items-center">
            GET IN TOUCH 
            </h2>
          
          </div>

          <div
            ref={ref}
            className="grid grid-cols-1 md:grid-cols-3 gap-5 justify-items-center items-center pb-10 "
          >
            <motion.div
              animate={animation}
              // initial={{ x: '-100vh' }}
              // animate={{ x: 0 }}
              // transition={{ type: 'spring', duration: 1, bounce: .6 }}
              className="grid justify-items-center gap-2 border p-2 w-full rounded font-semibold py-10 hover:bg-white/10"
            >
              <div>
                <BsFillBuildingFill className="text-3xl text-sky-300 " />
              </div>
              <span>2nd Floor, Sahu Complex, Ramnagar, Near Disha College, Raipur (C.G.)-492001

</span>
  <span className="text-gray-400 " >Cell: 8319308575, 8349937544, 8640091593</span>
            </motion.div>

            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", duration: 1, bounce: 0.6 }}
              className="grid justify-items-center gap-2 border p-2 w-full rounded font-semibold py-10 hover:bg-white/10"
            >
              <div>
                <BsGraphUpArrow className="text-3xl text-sky-300 " />
              </div>
              <span>8640091593
</span>
<span className="text-gray-400 " >Mon-Fri 10:00am-06:00pm </span>
            </motion.div>

            <motion.div
              initial={{ x: "100vh" }}
              animate={{ x: 0 }}
              transition={{ type: "spring", duration: 1, bounce: 0.6 }}
              className="grid justify-items-center gap-2 border p-2 w-full rounded font-semibold py-10 hover:bg-white/10"
            >
              <div>
                <BsNewspaper className="text-3xl text-sky-300 " />
              </div>
              <span>info@yashvitech.com
</span>
<span className="text-gray-400 " >24/7 online support </span>
            </motion.div>
          </div>


          {/* Main Contact Form */}
          <MainContactForm />


          {/* Social Media */}
          <div className="flex flex-col gap-4 justify-center items-center py-10">
            <h2 className="text-xl">Our Social Sites</h2>


            <div className="grid gap-2 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 ">

              <Link
                to="https://twitter.com/yashvitech39062"
                target="_blank"
                className="cursor-pointer"
              >
                <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                  {" "}
                  <span className="text-blue-400 bg-white rounded-full">
                    <AiFillTwitterCircle />
                  </span>
                  Twitter
                </span>
              </Link>

              <Link
                to="https://www.facebook.com/profile.php?id=100094370628556"
                target="_blank"
                className="cursor-pointer"
              >
                <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                  {" "}
                  <span className="text-blue-500 bg-white rounded-full">
                    <BsFacebook />
                  </span>{" "}
                  Facbook
                </span>
              </Link>


              <Link
                to="https://youtube.com/@YashvitechITSolution?si=iRydMOEileM4odLQ"
                target="_blank"
                className="cursor-pointer"
              >
                <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                  {" "}
                  <span className="text-white bg-red-500 ">
                    <BsYoutube />
                  </span>{" "}
                  YouTube
                </span>
              </Link>


              <Link
                to="https://www.instagram.com/yashvitechraipur/"
                target="_blank"
                className="cursor-pointer"
              >
                <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                  {" "}
                  <span className="text-red-700 bg-white rounded-full">
                    <BsInstagram />
                  </span>
                  Instagram
                </span>
              </Link>

              <Link
                to="https://www.linkedin.com/in/yashvitech-it-solution-b10b1a282/"
                target="_blank"
                className="cursor-pointer"
              >
                <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                  {" "}
                  <span className="text-blue-700 bg-white rounded-full">
                    <BsLinkedin />
                  </span>{" "}
                  LinkedIn
                </span>
              </Link>

              <Link
                // to="https://t.me/"
                to="/contact"
                // target="_blank"
                className="cursor-pointer"
                onClick={()=>window.scroll(0,0)}
              >
                <span className="border px-5 py-2 rounded-sm flex  items-center gap-2">
                  {" "}
                  <span className="text-blue-700 bg-white rounded-full"></span>
                  <BsTelegram /> Telegram
                </span>
              </Link>

            </div>
          
            
           
          </div>
        </div>
      </div >
    </>
  );
};

export default ContactUs;
