import React, { useEffect } from "react";
// import block from "../assets/block.png";
// import node from "../assets/node.png";
// import react from "../assets/mogo.png";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import react1 from "../assets/react1.png";
// import js from "../assets/js black2.png";
// import { Link } from "react-router-dom";
// import img from "../assets/website.jpg";
// import { useGetTechnoQuery } from "../services/profile";
import Loader from "./Loader";
// import { BsArrowDownShort } from "react-icons/bs";

const MainPageCard = () => {
  // const { data, isLoading } = useGetTechnoQuery();
  // console.log(data, "15");
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: "-100vh",
        opacity: 0,
      });
    }
  }, [animation, inView]);

  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <motion.div className="flex min-h-screen  items-center justify-center bg-white flex-col py-2  ">
          <div class="flex items-center justify-center text-center  w-[100%]">
            <div class="flex flex-col items-center justify-center rounded-tr-full rounded-bl-full w-full bg-white">
              <div class="flex flex-col p-2 m-2 w-full">
              <div class="text-xl md:text-3xl font-medium"> OUR TECHNOLOGIES </div>
              <div className="text-center">We are working and training -</div>

                <div class="flex items-center justify-center md:flex-row flex-col space-x-4 space-y-4 p-2">
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        src="py.png"
                        alt=""
                        class=""
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Software
                    </div> */}
                   
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl border-collapse border-1  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        // src="https://source.unsplash.com/300x300/?man,businessman"
                        src="django.jpg"
                        alt=""
                        class="m-auto "
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Mobile Apps
                    </div>
                  */}
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        // src="https://source.unsplash.com/300x300/?girl,woman"
                        src="javas.jpg"
                        alt=""
                        class=""
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Web Service
                    </div> */}
                
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        // src="https://source.unsplash.com/300x300/?man,boy"
                        src="reac.png"
                        alt=""
                        class=""
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Digital Marketing
                    </div> */}
                    
                    
                  </div>
                </div>
                <div class="flex items-center justify-center md:flex-row flex-col space-x-4 space-y-4 p-2">
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        // src="https://source.unsplash.com/300x300/?girl"
                        src="native1.png"
                        alt=""
                        class=""
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Software
                    </div> */}
                   
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl border-collapse border-1  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        // src="https://source.unsplash.com/300x300/?man,businessman"
                        src="sql.png"
                        alt=""
                        class="m-auto "
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Mobile Apps
                    </div> */}
                 
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        // src="https://source.unsplash.com/300x300/?girl,woman"
                        src="htm.png"
                        alt=""
                        class=""
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Web Service
                    </div> */}
                
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[90%] md:w-1/4">
                    <div class="h-40 w-40 m-auto  border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                      <img
                        // src="https://source.unsplash.com/300x300/?man,boy"
                        src="css3.png"
                        alt=""
                        class=""
                      />
                    </div>

                    {/* <div class="text-lg font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Digital Marketing
                    </div> */}
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default MainPageCard;
