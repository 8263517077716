import React from 'react'
// import AboutCard from '../components/About/AboutCard'
import AboutToggle from '../components/About/AboutToggle'
// import BeliveInAction from '../components/About/BeliveInAction'
// import CollapsMenu from '../components/About/CollapsMenu'
// import GridAbout from '../components/About/GridAbout'
// import SliderAbout from '../components/About/SliderAbout'
import SwiperAbout from '../components/About/SwiperAbout'
// import TalkToExperts from '../components/About/TalkToExperts'
// import MainSwiperAbout from '../components/MainSwiperAbout'
// import MainPageCard from '../components/MainPageCard'
import { FaGreaterThan } from 'react-icons/fa'

const About = () => {
    return (
        <>
            {/* <MainSwiperAbout /> */}
            <div className="bg-[url(inner_page_banner.jpg)  ] py-20 bg-center text-white " style={{backgroundImage:"url(inner_page_banner.jpg)" }} >

<div className=' pl-10 sm:pl-44 sm:py-5' >

<button className='font-[550] text-[40px] ' >About Us</button>
<div className="flex text-base gap-2 ">

<button className='text-sky-400 '> Home</button> <FaGreaterThan style={{marginTop:"6px" }}  /> <button> About Us </button>
</div>

</div>
</div>
            {/* <SliderAbout /> */}
            <AboutToggle />
            {/* <GridAbout /> */}
            {/* <MainPageCard /> */}
            <SwiperAbout />
            {/* <TalkToExperts /> */}
            {/* <BeliveInAction /> */}
            {/* <CollapsMenu /> */}
        </>
    )
}

export default About
